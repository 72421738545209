/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import { getWindow } from '../state/utils/utilities-dom'
import { scriptCookies } from '../utils/scriptCookies'
import { scriptBlueConic } from '../utils/scriptBlueConic'
import { scriptVWO } from '../utils/scriptVWO'
import { gtmTagManagment } from '../utils/gtmTagManagment'

function SEO(props) {
	const { site, allContentfulTenaPaises } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						siteUrl
						locale
					}
				}
				allContentfulTenaPaises(
					filter: {
						node_locale: { eq: "es-CO" }
						codigoDeDosLetras: { ne: "N/A" }
					}
				) {
					nodes {
						nombreDelPais
						codigoDeDosLetras
						codigoDelPais
						prefijoTelefonico
						node_locale
						lenguaje
						dominio
					}
				}
			}
		`
	)

	const description = props.description
	const lang = props.lang || site.siteMetadata.locale
	const meta = props.meta
	const title = props.title
	const alternatesInvalidCountries = props.alternatesInvalidCountries || []
	const metaDescription = description || site.siteMetadata.description
	const author = site.siteMetadata.author
	const slug = props.slug || getWindow()?.location?.pathname || '/'
	const siteUrl = site.siteMetadata.siteUrl
	const ogImageUrl = props.image || siteUrl + '/logo.png'
	const script = props.script
	const { linkVWO, jsVWO } = scriptVWO(process.env.LOCALE);
	const gtmTagGoogle = gtmTagManagment(process.env.LOCALE)
	// Organization Schema
	const org = {
		'@context': 'https://schema.org',
		'@type': 'Organization',
		name: 'TENA',
		alternateName: author,
		url: siteUrl,
		logo: ogImageUrl,
		contactPoint: {
			'@type': 'ContactPoint',
			telephone: '+57018000524848',
			contactType: 'customer service',
			contactOption: 'TollFree',
			areaServed: 'CO',
			availableLanguage: 'es',
		},
		sameAs: 'https://www.facebook.com/TenaBienEstar',
	}

	// WebSite Schema
	const website = {
		'@context': 'https://schema.org',
		'@type': 'WebSite',
		'@id': siteUrl + '#website',
		headline: 'TENA',
		name: 'TENA',
		description: site.siteMetadata.description,
		url: siteUrl,
		potentialAction: {
			'@type': 'SearchAction',
			target: siteUrl + '?s={search_term_string}',
			'query-input': 'required name=search_term_string',
		},
	}

	let schemas = []
	if (script) {
		schemas = [org, website, script]
	} else {
		schemas = [org, website]
	}

	const schemaOrgJSONLD = {
		type: 'application/ld+json',
		innerHTML: JSON.stringify(schemas),
	}

	const urlFinal = new URL(siteUrl + slug)

	const hrefLangList = []

	allContentfulTenaPaises.nodes?.forEach(country => {
		const slugWithOutInitialSlash = slug.replace(/^\//, '')
		const finalUrl = new URL(country.dominio + slugWithOutInitialSlash)
		const isInvalidCountry = alternatesInvalidCountries?.find(_country =>
			country.codigoDeDosLetras
				?.toLowerCase()
				?.includes(_country?.toLowerCase())
		)

		if (!isInvalidCountry) {
			hrefLangList.push({
				rel: 'alternate',
				href: finalUrl.href,
				hreflang: `${country.lenguaje}-${country.codigoDeDosLetras}`,
			})
		}
	})

	const getDynamicScripts = (locale) => {
        const cookiesScripts = scriptCookies[locale] ? scriptCookies[locale].concat([schemaOrgJSONLD]) : scriptCookies.es.concat([schemaOrgJSONLD]);
        const blueConicScripts = scriptBlueConic[locale] ? scriptBlueConic[locale] : scriptBlueConic.es;
        return [...cookiesScripts, ...blueConicScripts, ...jsVWO, ...gtmTagGoogle];
    };

	return (
		<Helmet
			htmlAttributes={{ lang }}
			title={title}
			titleTemplate={`%s`}
			link={([
				{
					rel: 'icon',
					type: 'image/png',
					href: '/favicon.ico',
				},
				{
					rel: 'alternate',
					href: `https://www.tena.com.co${slug}`,
					hreflang: 'es',
				},
				{
					rel: 'alternate',
					href: `https://www.tena.com.co${slug}`,
					hreflang: 'x-default',
				},
			]
			).concat(hrefLangList, linkVWO)}
			meta={[
				{
					property: `fb:app_id`,
					content: `199249840200202`,
				},
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `image`,
					content: ogImageUrl,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:image`,
					content: ogImageUrl,
				},
				{
					property: `og:image:width`,
					content: `400`,
				},
				{
					property: `og:image:height`,
					content: `300`,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:url`,
					content: urlFinal,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					name: `twitter:image`,
					content: ogImageUrl,
				},
				{
					name: 'google-site-verification',
					content: process.env.GOOGLE_SITE_VERIFICATION,
				},
				{
					name: 'facebook-domain-verification',
					content: '2dwk4v8zjyj7vauw7atw6rqwjf6xzr',
				},
				props.isNoIndex ?
				{
					name: 'robots',
					content: 'noindex',
				}: '',
			].concat(meta)}
			script={getDynamicScripts(process.env.LOCALE)}
			
		/>
	)
}

SEO.defaultProps = {
	lang: `es-CO`,
	meta: [],
	description: ``,
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
}

export default SEO
